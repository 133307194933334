// Filename - components/Footer.js

import React from "react";
import {
	Box,
	FooterContainer,
	Row,
	Column,
	FooterLink,
	Heading,
} from "./FooterStyles";
import { FaXTwitter } from "react-icons/fa6";
import { ImYoutube2 } from "react-icons/im";
import { FaFacebook } from "react-icons/fa6";
import { GrInstagram } from "react-icons/gr";
import { FaPhoneVolume } from "react-icons/fa";
import { MdOutlineMarkEmailRead } from "react-icons/md";
import { BsMailboxFlag } from "react-icons/bs";

const Footer = () => {
	return (
		<Box>
			<h1
				style={{
					color: "#84ef84",
                    fontWeight: 'bold',
					textAlign: "center",
					marginTop: "10px",
				}}
			>
				Yosor Dream Transportaion Service!
			</h1>
			<FooterContainer>
				<Row>
					<Column>
						<Heading>About Us</Heading>
						<FooterLink href="mission">
							Mission
						</FooterLink>
						<FooterLink href="vision">
							Vision
						</FooterLink>
						{/* <FooterLink href="#">
							Testimonials
						</FooterLink> */}
					</Column>
				
					<Column>
						<Heading>Contact Us</Heading>
						<FooterLink href="tel:+16147465234">
							<FaPhoneVolume /> : 614 746 5234
						</FooterLink>
						<FooterLink href="mailto:gemtayoseph@gmailc.com">
							<MdOutlineMarkEmailRead />: gemtayoseph@yosortransportation.com
						</FooterLink>
						<FooterLink href="#">
							<BsMailboxFlag /> : 4449 Easton Way, 2 nd Floor
Columbus, Oh 43219
						</FooterLink>
						
					</Column>
					<Column>
						<Heading>Social Media</Heading>
						<FooterLink target="_blank" href="https://www.facebook.com/profile.php?id=61558410607600">
							<i className="fab fa-facebook-f">
								<span
									style={{
										marginLeft: "10px",
									}}
								>
                                <FaFacebook /> Facebook
								</span>
							</i>
						</FooterLink>
						{/* <FooterLink target="_blank" href="http://facebook.com/y.gemta">
							<i className="fab fa-instagram">
								<span
									style={{
										marginLeft: "10px",
									}}
								>
									<GrInstagram /> Instagram
								</span>
							</i>
						</FooterLink>
						<FooterLink target="_blank" href="http://facebook.com/y.gemta">
							<i className="fab fa-twitter">
								<span
									style={{
										marginLeft: "10px",
									}}
								>
									<FaXTwitter /> Twitter
								</span>
							</i>
						</FooterLink>
						<FooterLink  target="_blank" href="http://facebook.com/y.gemta">
							<i className="fab fa-youtube">
								<span
									style={{
										marginLeft: "10px",
									}}
								>
									<ImYoutube2 /> Youtube
								</span>
							</i>
						</FooterLink> */}
					</Column>
				</Row>
			</FooterContainer>
		</Box>
	);
};
export default Footer;
