
import React from "react";

const Service = () => {
	return (
		
		<div style={{ "textAlign": "center" }}>
			<h2 style={{ color:"green",fontWeight:"bold" }}>Our Services</h2>
			<ul>
				<li>We provide door to door and curb side to curb side non-emergency medical transportation services. Ensuring timely arrivals for doctors’ appointments, important medical checks, and consultations etc.</li> 
				<li>At pick up and drop off time, our drivers will assist physically disabled customers and those who need help for different reasons.</li>
				<li>We are more than just a medical transport provider; we serve you with compassion and care at large.</li>
			</ul>
		</div>
		
	);
};

export default Service;
