
import React from "react";

const Vision = () => {
	return (
		
		<div style={{ "textAlign": "center" }}>
			<h2 style={{ color:"green",fontWeight:"bold" }}>Our Vision</h2>
			Our vision is to make sure that the community in which we serve gets the best and professional transportation service in the future. We believe that our company will play a role model in achieving this goal without any hesitation.
		</div>
		
	);
};

export default Vision;
