import { useEffect, useState } from "react";
import axios from "axios";
import React from "react";
import DateTimePicker from 'react-datetime-picker';
import 'react-datetime-picker/dist/DateTimePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';
type ValuePiece = Date | null;

type DateValue = ValuePiece | [ValuePiece, ValuePiece];
const Schedule = () => {
	const [inputs, setInputs] = useState([]);
    const [sent, setSent] = useState(false);
    const [errors,setErrors] = useState({})
    const [sentMsg, setSentMsg] = useState("");
    const [color, setColor] = useState("");
    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({...values, [name]: value}));
    }
    function clearFields(event: any) {
       Array.from(event.target).forEach((e:any) => {if(e.name ==='Name' || e.name ==='phoneNumber' || e.name ==='email'){
       return;}else{
        e.value = "";
       }});
      }
    const handleSubmit = async(event:any) => {
        event.preventDefault();
        if(handleValidation(event)){
        setInputs(inputs => ({...inputs, appointmentTime: appointmentTime}));   
    const response = await  axios.post('https://yosortransportation.com/schedule.php', {input: inputs},{headers: {
        'Access-Control-Allow-Origin':'*'
      }})
      if(response.data?.status===1){
        setSentMsg("successfully reserved. Now you can add another appointment");
        setColor("green");
        clearFields(event);
      }else{
        setSentMsg("There is a problem with the system. Please try again");
        setColor("red");
      }
      setSent(true)
}
}
function handleValidation(event: any) {
    const data = new FormData(event.target);
    const Name = data.get("Name")?.valueOf().toString();
    const email = data.get("email")?.valueOf().toString();
    const phoneNumber = data.get("phoneNumber")?.valueOf().toString();
    const address = data.get("address")?.valueOf().toString();
    const city = data.get("city")?.valueOf().toString();
    const state = data.get("state")?.valueOf().toString();

    const postcode = data.get("postcode")?.valueOf().toString();
    const daddress = data.get("daddress")?.valueOf().toString();

    const dcity = data.get("dcity")?.valueOf().toString();

    const dstate = data.get("dstate")?.valueOf().toString();

    const dpostcode = data.get("dpostcode")?.valueOf().toString();
    const riderSize = data.get("riderSize")?.valueOf().toString();
    let errors = {};
    let formIsValid = true;

    if (!address) {
        formIsValid = false;
        errors["address"] = "Address Cannot be empty";
      }
      if (!city) {
        formIsValid = false;
        errors["city"] = "City Cannot be empty";
      }
      if (!state) {
        formIsValid = false;
        errors["state"] = "State Cannot be empty";
      }
      if (!postcode) {
        formIsValid = false;
        errors["postcode"] = "Zip Code Cannot be empty";
      }else{
        const zipCode = /^\d{5}$/;
        if(!zipCode.test(postcode)){
            formIsValid = false;
            errors["postcode"] = "Zip code should be 5 digits";
        }
      }
      
      if (!daddress) {
        formIsValid = false;
        errors["daddress"] = "Address Cannot be empty";
      }
      if (!dcity) {
        formIsValid = false;
        errors["dcity"] = "City Cannot be empty";
      }
      if (!dstate) {
        formIsValid = false;
        errors["dstate"] = "State Cannot be empty";
      }
      if (!dpostcode) {
        formIsValid = false;
        errors["dpostcode"] = "Zip Code Cannot be empty";
      }else{
        const zipCode = /^\d{5}$/;
        if(!zipCode.test(dpostcode)){
            formIsValid = false;
            errors["dpostcode"] = "Zip code should be 5 digits";
        }
      }
      if (!riderSize) {
        formIsValid = false;
        errors["riderSize"] = "number of person Cannot be empty";
      }
    //Name
    if (!Name) {
      formIsValid = false;
      errors["Name"] = "Name Cannot be empty";
    }
    else{
        if((Name).trim().length>=3){
            if (!Name.match(/^[a-zA-Z '.-]*$/)) {
              formIsValid = false;
              errors["Name"] = "Name should be only a combination of letters, space, dot or hyphen";
            }
          }else{
              formIsValid = false;
              errors["Name"] = "Name should not be less than three characters";
          }
      }
    if (!email) {
        formIsValid = false;
        errors["email"] = "Email Cannot be empty";
      }
      else {
     const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
        formIsValid = false;
        errors["email"] = "Email is not valid";
      }
    }
    if(!phoneNumber){
        formIsValid = false;
        errors["phoneNumber"] = "Phone cannot be empty";
    }else{
        if (!phoneNumber.match(/(\+\d{1,3}\s?)?((\(\d{3}\)\s?)|(\d{3})(\s|-?))(\d{3}(\s|-?))(\d{4})(\s?(([E|e]xt[:|.|]?)|x|X)(\s?\d+))?/g)) {
            formIsValid = false;
            errors["phoneNumber"] = "Phone number is not valid";
          }
    }

    
    setErrors( errors);
    return formIsValid;
  }
var today = new Date();
var dd = today.getDate();
var mm = today.getMonth();
var yyyy = today.getFullYear()+1;
var maxDate= yyyy + '-' + mm +'-' + dd; 
console.log(maxDate)
const [appointmentTime, onChange] = useState<DateValue>(new Date());
    console.log(appointmentTime)
    useEffect(()=>{
        setInputs(inputs => ({...inputs, appointmentTime: appointmentTime}));
    },[appointmentTime])
return (
        <div>
            <h2 style={{ color:"green",fontWeight:"bold" }}>Make an upfront reservation</h2>
            {sent && (<label style={{color:color}}>{sentMsg}</label>)}
            <form onSubmit={handleSubmit}>
                <table cellSpacing="10">
                    <tbody>
                        <tr>
                            <th>
                                <label>Full Name: </label>
                            </th>
                            <td>
                                <input type="text" name="Name" maxLength={60} onChange={handleChange} />
                                <br></br><span style={{ color: "red" }}>{errors["Name"]}</span>
                            </td>
                        </tr>
						<tr>
                            <th>
                                <label>Phone number: </label>
                            </th>
                            <td>
                                <input type="text" name="phoneNumber" maxLength={12} onChange={handleChange} />
                                <br></br><span style={{ color: "red" }}>{errors["phoneNumber"]}</span>
                            </td>
                        </tr>
						<tr>
                            <th>
                                <label>email: </label>
                            </th>
                            <td>
                                <input type="text" name="email" maxLength={40} onChange={handleChange} />
                                <br></br><span style={{ color: "red" }}>{errors["email"]}</span>
                            </td>
                        </tr>
                        <tr><th></th>
                            <td><h5> Pick up Address</h5></td>
                        </tr>
                        <tr>
                            <th>
                                <label>street Address: </label>
                            </th>
                            <td>
                            <input name="address" placeholder="street Address" maxLength={80} type="text" onChange={handleChange} />
                            <br></br><span style={{ color: "red" }}>{errors["address"]}</span>
                            </td>
                        </tr>
                        <tr>
                            <th>
                                <label>City: </label>
                            </th>
                            <td>
                            <input name="city" placeholder="city" type="text" maxLength={80} onChange={handleChange} />
                            <br></br><span style={{ color: "red" }}>{errors["city"]}</span>
                            </td>
                        </tr>
                        <tr>
                            <th>
                                <label>State: </label>
                            </th>
                            <td>
                            <input name="state" placeholder="state" type="text" maxLength={60} onChange={handleChange} />
                            <br></br><span style={{ color: "red" }}>{errors["state"]}</span>
                            </td>
                        </tr>
                        <tr>
                            <th>
                                <label>Zip code: </label>
                            </th>
                            <td>
                            <input name="postcode" placeholder="zip code" type="text" maxLength={6} onChange={handleChange} />
                            <br></br><span style={{ color: "red" }}>{errors["postcode"]}</span>

                            </td>
                        </tr>
                        <tr><th></th>
                            <td><h5> Drop off Address</h5></td>
                        </tr>
                        <tr>
                            <th>
                                <label>street Address: </label>
                            </th>
                            <td>
                            <input name="daddress" placeholder="street Address" type="text" maxLength={80} onChange={handleChange} />
                            <br></br><span style={{ color: "red" }}>{errors["daddress"]}</span>
                            </td>
                        </tr>
                        <tr>
                            <th>
                                <label>City: </label>
                            </th>
                            <td>
                            <input name="dcity" placeholder="city" type="text" maxLength={80} onChange={handleChange} />
                            <br></br><span style={{ color: "red" }}>{errors["dcity"]}</span>
                            </td>
                        </tr>
                        <tr>
                            <th>
                                <label>State: </label>
                            </th>
                            <td>
                            <input name="dstate" placeholder="state" type="text" maxLength={60}  onChange={handleChange} />
                            <br></br><span style={{ color: "red" }}>{errors["dstate"]}</span>
                            </td>
                        </tr>
                        <tr>
                            <th>
                                <label>Zip code: </label>
                            </th>
                            <td>
                            <input name="dpostcode" placeholder="zip code" type="text" maxLength={6} onChange={handleChange} />
                            <br></br><span style={{ color: "red" }}>{errors["dpostcode"]}</span>
                            </td>
                        </tr>
                        <tr>
                            <th>
                                <label>Number of persons: </label>
                            </th>
                            <td>
                                <input type="number" name="riderSize" onChange={handleChange} />
                                <br></br><span style={{ color: "red" }}>{errors["riderSize"]}</span>
                            </td>
                        </tr>
                        <tr>
                            <th>
                                <label>Message: (Optional) </label>
                            </th>
                            <td> 
                                <textarea type="text" name="message" onChange={handleChange} />
                            </td>
                        </tr>
                        <tr>
                            <th>
                                <label>Pick up time: </label>
                            </th>
                            <td>
							<div>
      <DateTimePicker onChange={onChange} value={appointmentTime} minDate={new Date()} maxDate={new Date(maxDate)} monthPlaceholder={"mm"}  dayPlaceholder={"dd"} yearPlaceholder={"yyyy"} hourPlaceholder={"hh"} minutePlaceholder={"mm"} amPmAriaLabel={"AM"} showLeadingZeros={true}/>
    </div>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan="2" align ="right">
                                <button>Submit</button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </form>
        </div>
    )
};

export default Schedule;
