import React from "react";
import { FaPhoneVolume } from "react-icons/fa";
import { MdOutlineMarkEmailRead } from "react-icons/md";
import { BsMailboxFlag } from "react-icons/bs";
const About = () => {
    return (
        <div>
            <h2 style={{ color:"green",fontWeight:"bold",textAlign: "center"}}> Contact Us</h2>
            <div style={{ "marginLeft": "20%" }}>
            <p>
            <FaPhoneVolume /> Phone: <a href="tel:+1234567" >
                     614 746 5234</a>
            </p>
            <p>
            <MdOutlineMarkEmailRead />Email: <a href="mailto:gemtayoseph@yosortransportation.com">
            gemtayoseph@yosortransportation.com
                </a>
            </p>
            <p>
            <BsMailboxFlag /> Office Address: <a href="#">
                     4449 Easton Way, 2 nd Floor
                    Columbus, Oh 43219
                </a>
            </p>
            </div>
        </div>
    )
};

export default About;
