import React from "react";
import { useState } from "react";
import axios from "axios";
const Feedback = () => {
	const [inputs, setInputs] = useState([]);
    const [sent, setSent] = useState(false);
    const [sentMsg, setSentMsg] = useState("");
    const [color, setColor] = useState("");
    const [errors,setErrors] = useState({})
    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({...values, [name]: value}));
    }
    const handleSubmit = async(event) => {
        event.preventDefault();
        if(handleValidation(event)){
    const response = await  axios.post('https://yosortransportation.com/feedBack.php', {input: inputs},{headers: {
        'Access-Control-Allow-Origin':'*'
      }})
      if(response.data?.status===1){
        setSentMsg("Feedback successfully created");
        setColor("green");
      }else{
        setSentMsg("There is a problem with the system. Please try again");
        setColor("red");
      }
      setSent(true)
    }
}
function handleValidation(event: any) {
    const data = new FormData(event.target);
    const Name = data.get("Name")?.valueOf().toString();
    const feedback = data.get("feedback")?.valueOf().toString();
    let errors = {};
    let formIsValid = true;

    if (!Name){
        formIsValid = false;
        errors["Name"] = "Name Cannot be empty";
      }
      else{
       if((Name).trim().length>=3){
          if (!Name.match(/^[a-zA-Z '.-]*$/)) {
            formIsValid = false;
            errors["Name"] = "Name should be only a combination of letters, space, dot or hyphen";
          }
        }else{
            formIsValid = false;
            errors["Name"] = "Name should not be less than three characters";
        }
        }
        if (!feedback) {
            formIsValid = false;
            errors["feedback"] = "feedback Cannot be empty";
          }

          setErrors( errors);
          return formIsValid;
}
    return (
        <div> 
            <h2 style={{ color:"green",fontWeight:"bold" }}>Send us your feedback</h2>
            {sent && (<label style={{color:color}}>{sentMsg}</label>)}
            <form onSubmit={handleSubmit} >
                <table cellSpacing="10">
                    <tbody>
                        <tr>
                            <th>
                                <label>Name: </label>
                            </th>
                            <td>
                                <input type="text" name="Name" onChange={handleChange} />
                                <br></br><span style={{ color: "red" }}>{errors["Name"]}</span>
                            </td>
                        </tr>
                        <tr>
                            <th>
                                <label>message: </label>
                            </th>
                            <td> 
                                <textarea type="text" name="feedback" onChange={handleChange} />
                                <br></br><span style={{ color: "red" }}>{errors["feedback"]}</span>

                            </td>
                        </tr>
                        
                        <tr>
                            <td colSpan="2" align ="right">
                                <button>Submit</button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </form>
        </div>
    )
};

export default Feedback;
