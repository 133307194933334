// pages/index.js

import React from 'react';
import logoBar from './bannerBar2.png'

const Home = () => {
return (
	<div
	// style={{
	// 	display: 'flex',
	// 	justifyContent: 'centre',
	// 	alignItems: 'centre',
	// 	height: '100vh'
	// }}
    className='topNavBar'
	>
    <img src={logoBar} alt="bug" height={70} style={{width:'100%'}} />
	</div>
);
};

export default Home;
