

import React from "react";

const Mission = () => {
	return (
		<div style={{ "textAlign": "center" }}>
			<h2 style={{ color:"green",fontWeight:"bold" }}>Our Mission:</h2>
			We move the transportation industry one step forward by offering safe, dependable, and
			professional services to our community.
		</div>
	);
};

export default Mission;
