

import ReactPaginate from 'react-paginate';
import { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import logo from './yosorIcon.jpeg'
import { Navbar, Container, Nav, NavDropdown } from 'react-bootstrap';
import {Carousel } from 'react-bootstrap';  
import img1 from '../src/image/car1.png';
import img2 from '../src/image/car2.png' ; 
import img3 from '../src/image/car3.png' ; 
import Footer from "./components/Footer";
import './App.css';
import {
  Routes,
  Route,useLocation
} from "react-router-dom";
import Home from "./Home.js";
import ContactUs from "./ContactUs.js";
import Feedback from "./Feedback.tsx";
import Mission from "./Mission.js";
import Vision from "./Vision.js";
import Schedule from "./Schedule.tsx";
import Service from "./Service.js";
function App() {
  const [items, setItems] = useState([]);

  const [pageCount, setpageCount] = useState(0);
  const [showHomePage,setShowHomePage] = useState(true)

  let limit = 3;
let location = useLocation();
//console.log(location);
 //  const url= window.location.href | "https://yosortransportation.com";
useEffect(()=>{
if(location.pathname !=="/"){
  setShowHomePage(false);
}
},[location.pathname])
  useEffect(() => {
    const getComments = async () => {
      const res = await fetch(
        //`http://localhost:3004/comments?_page=1&_limit=${limit}`
        `https://yosortransportation.com/getPost.php?_page=1&_limit=${limit}`
      );
      const data = await res.json();
      const total = res.headers.get("x-total-count");
      setpageCount(Math.ceil(total / limit));
       
      setItems(data);
    };

    getComments();
  }, [limit]);
//      `https://yosortransportation.com/getPost.php?_page=${currentPage}&_limit=${limit}`

  const fetchComments = async (currentPage) => {
    const res = await fetch(
      `https://yosortransportation.com/getPost.php?_page=${currentPage}&_limit=${limit}`
    );
    const data = await res.json();
    return data;
  };
  const handlePageClick = async (data) => {

    let currentPage = data.selected + 1;

    const commentsFormServer = await fetchComments(currentPage);

    setItems(commentsFormServer);
  };
  return (
    <div>
       <div className="row">
       <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">  
        <Container>  
          <Navbar.Brand href="#home"><img src={logo} alt="bug" height={50} /></Navbar.Brand>  
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />  
          <Navbar.Collapse id="responsive-navbar-nav">  
            <Nav className="me-auto">  
              <Nav.Link href="/">Home</Nav.Link>  
              <NavDropdown title="Service" id="collasible-nav-dropdown">  
                <NavDropdown.Item href="service">Service</NavDropdown.Item>  
         
                {/* <NavDropdown.Divider />  
                <NavDropdown.Item href="#action/Service4">Service 4</NavDropdown.Item>   */}
              </NavDropdown>  
              <Nav.Link href="/mission">Mission</Nav.Link> 
              <Nav.Link href="/vision">Vision</Nav.Link>  
              <Nav.Link href="/Schedule">Schedule</Nav.Link>  
              <Nav.Link href="/contactus">Contact Us</Nav.Link>   
            </Nav> 
            <Nav>  
              <Nav.Link href="/feedback">Feedback</Nav.Link>
            </Nav>  
          </Navbar.Collapse>  
        </Container>  
      </Navbar>  
       
        </div>
<div className="row m-2">
<div className="col-sm-9">
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/contactus" element={<ContactUs />} />
                <Route path="/feedback" element={<Feedback />} />
                <Route path="/service" element={<Service />} />
                <Route
                    path="/mission"
                    element={<Mission />}
                />
                <Route
                    path="/vision"
                    element={<Vision />}
                />
                <Route path="/Schedule" element={<Schedule />} />
             
            </Routes>
  {/* <CreateUser /> */}


  

      {showHomePage && <><div className="row m-0">
        {items.map((item) => {
          return (
            <div key={item.id} className="col-sm-12">
              <div className="card" style={{ minHeight: 225 }}>
                <div className="card-body">
                  {item.title && (<h5 className="card-title text-center h2" style={{color:"green",fontWeight:"bold"}}>{item.title} </h5>)}
                  
                  {item.body && (<p className="card-text" dangerouslySetInnerHTML={{__html: item.body }}></p>)}
                  {item.image && (<img  src={'https://yosortransportation.com/uploads/'+item.image} alt="bug"  style={{width: "100%",height:"80%"}} />)}
                  <h6 className="card-subtitle mb-2 text-muted text-center">
                    {item.date}
                  </h6>
                </div>
              </div>
            </div>
          );
        })}
      </div>

      <ReactPaginate
previousLabel='Previous'
      nextAriaLabel='Next'
      breakLabel="..."
      pageCount={pageCount}
      marginPagesDisplayed={4}
      onPageChange={handlePageClick}
  containerClassName='pagination justify-content-center'
  pageClassName='page-item'
  pageLinkClassName='page-link'
  previousClassName='page-item'
  previousLinkClassName='page-link'
  nextClassName='page-item'
  nextLinkClassName='page-link'
  breakClassName='page-item'
  breakLinkClassName='page-link'
  activeClassName='active'

      /></>
      }
      </div>
      <div className="card col-sm-3">
      <div className='p-5' style={{align:"center"}}>  
    <Carousel fade>  
  <Carousel.Item>  
  <img   
      className="d-block"  
      src={img1}  
      alt="First slide"  
    />  
    {/* <Carousel.Caption style={{color:"black"}}>  
      <h3>First Slider Image Title</h3>  
      <p>First Slide decription.</p>  
    </Carousel.Caption>   */}
  </Carousel.Item>  
  <Carousel.Item>  
    <img  
      className="d-block"  
      src={img2}  
      alt="Second slide"  
    />  
  </Carousel.Item>  
  <Carousel.Item>  
    <img  
      className="d-block"  
      src={img3}  
      alt="Third slide"  
    />  
  
  </Carousel.Item>  
</Carousel>  
</div>
</div>
      </div>
             <div className="row ">
             <Footer />
             </div>

    </div>
  );
}

export default App;
